import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Helmet} from "react-helmet";
import "../assets/css/style.css";
import Navbar from "../components/navbar";
import Terms from "../components/terms";
import Contact from "../components/contact";
import Footer from "../components/footer";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
// markup
const TermsPage = () => {

  const data = useStaticQuery(graphql`
        query TermsPage {
            wpProject {
                projectMeta {
                  projectId
                  project
                }
            }
            wpTitle {
              titleMeta {
                title
                favicon {
                  sourceUrl
                }
              }
            }
            wpThemeColor {
              themeColorMeta {
                primary
                secondary
                background
              }
            }
            wpTagmanager {
              metaTagManager {
                clickGuard
              }
            }
        }
    `)
  
  const projectid = data.wpProject.projectMeta.projectId
  const project = data.wpProject.projectMeta.project
  const title = data.wpTitle.titleMeta.title
  const favicon = data.wpTitle.titleMeta.favicon.sourceUrl
  const background = data.wpThemeColor.themeColorMeta.background
  const primarycolor = data.wpThemeColor.themeColorMeta.primary
  const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

  let clickGuard = ""
    if(data.wpTagmanager.metaTagManager.clickGuard != ""){
      clickGuard = data.wpTagmanager.metaTagManager.clickGuard;
    }

  React.useEffect(()=>{
    document.documentElement.style.setProperty('--bg-color', background);
    document.documentElement.style.setProperty('--primary', primarycolor);
    document.documentElement.style.setProperty('--secondary', secondarycolor);
  },[])

  return (
    <main style={pageStyles}>
      <Helmet>
        <title>{title}</title>
        <script src='https://ginfomedia.com/mlschatbot/chatbot.js'></script>
        <link rel="apple-touch-icon" type="image" href={favicon} />
        <link rel="icon" type="image" href={favicon} />
        <script defer type="application/javascript" src={clickGuard}></script>
      </Helmet>
      <Navbar />
      <Terms />
      <Contact />
      <Footer />
      <input type="hidden" id="projectId" value={projectid} />
      <input type="hidden" id="projectName" value={project} />
    </main>
  )
}

export default TermsPage
