import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./style.css";

const Terms = () => {

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 12, lg: 15 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Terms & Condition
                        </Typography>
                        <hr />

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, fontSize: '15px' }}>
                            MAHA RERA Act has come into effect from 1st May 2017. The content of the website may not be fully compliant with RERA as of date. We are in process of revising further details required as per RERA if pending any.

                            We all possible measures to avoid any misrepresentation but make no warranties as to the content and information accuracy. While reasonable efforts are being made to ensure the authenticity and completeness of the Information displayed here and the same is updated at regular intervals there is a possibility that the information displayed may not be current and incomplete.

                            Please make sure you verify all the details from the actual site office or from the RERA website before making any decision in relation to the purchase of the properties.

                            We accept no liability for the content of this website content, or for the consequences of any actions taken on the basis of this website or content. Property Prices, location map image, master plan image, opinion/suggestions provided in this mail are subject to change without notice and shall in no way make us responsible for any loss/damages caused to the users/members.
                        </Typography>



                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Terms;